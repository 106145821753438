import React, { useCallback, useState } from "react";
import { Dialog, DialogContent, Grid, MenuItem, styled } from "@mui/material";
import { HighlightOffSharp } from "@mui/icons-material";
import Button from "../../components/buttons/PrimaryButton";
import Card from "../../components/Card";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import Select from "../../components/inputs/Select";
import { getClientes } from "../../selectors/dominios.selectors";
import { useSelector } from "react-redux";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const StyledGridContent = styled(Grid)`
  text-align: left;
  color: #ffffff;
  font-family: nunito;
  font-size: 22px;
  font-weight: 300;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  align-items: center;
`;

const CardTitle = styled("div")`
  font-family: patua one;
  font-size: 28px;
  font-weight: 400;
  font-spacing: 1.8px;
  color: #ffffff;
`;

const StyledCardActive = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledCloseSharp = styled(HighlightOffSharp)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.black};
  margin-left: 10px;
  cursor: pointer;
`;

const SelecionarClienteDialog = ({
  handleClose,
  open,
  handleConfirmarCliente
}) => {

  const [value, setValue] = useState("");
  const { data: clientes } = useSelector(getClientes);

  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      open={open}
      clientes={clientes}
    >
      {!!open && (
        <DialogContent>
          <Grid container spacing={4}>
            <IconsGrid item xs={12} md={12}>
              <StyledCloseSharp onClick={handleClose} />
            </IconsGrid>
            <Grid item xs={12}>
              <StyledCardActive>
                <Grid container spacing={4}>
                  <StyledGrid item xs={12} md={12}>
                    <CardTitle>Selecionar Cliente</CardTitle>
                  </StyledGrid>
                </Grid>
                <Grid container spacing={4}>
                  <StyledGridContent item xs={12} md={12}>
                    <Select
                      onChange={(e) => setValue(e.target.value)}
                      label="Clientes"
                    >
                      {clientes?.map((plano) => {
                        return (
                          <MenuItem key={plano.id} value={plano.id}>
                            {plano.nomeCliente}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </StyledGridContent>
                </Grid>
              </StyledCardActive>
            </Grid>
            <StyledGrid item xs={6}>
              <OutlinedButton onClick={handleClose}>Voltar</OutlinedButton>
            </StyledGrid>
            <StyledGrid item xs={6}>
              <Button onClick={() => handleConfirmarCliente(value)}>Confirmar</Button>
            </StyledGrid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default SelecionarClienteDialog;
