import BasePage from "../BasePage";
import React, { useCallback, useState } from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardAtualizacao from "./CardAtualizacao";
import Spinner from "../../components/spinner/Spinner";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import AlterarSituacaoDialog from "./AlterarSituacaoDialog";
import { Pagination, FilterChip } from "../../components";
import SelecionarClienteDialog from "./SelecionarClienteDialog";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  font-weight: 400;
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledH4 = styled("h4")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: ${({ theme }) => theme.colors.subtitle};
  letter-spacing: 4.8px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 20px;
  margin-top: 0px;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.25px;

  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
  margin-bottom: 10px;
`;

const StyledItem = styled(Grid)`
  max-height: 200px;
`;

const ManutencaoClientePage = ({
  data,
  page,
  count,
  loading,
  handleNewAtualizacao,
  handleDeleteManutencao,
  handleDownloadArtefato,
  filtrarManutencoes,
  usuarioCliente,
  handleAlterarSituacao,
  handlePagination,
  handleConfirmarCliente,
  cliente
}) => {
  const [dadosExclusaoManutencao, setDadosExclusaoManutencao] = useState({
    open: false,
  });

  const [dadosSituacaoManutencao, setDadosSituacaoManutencao] = useState({
    open: false,
  });

  const [dadosSelecionarCliente, setDadosSelecionarCliente] = useState({
    open: false,
  });

  const [filtro, setFiltro] = useState({
    novo: true,
    aguardandoConclusao: true,
    finalizado: true,
  });

  const abrirNovo = () => {
    if (cliente)
      handleNewAtualizacao();
    else
      setDadosSelecionarCliente({open: true});
  };

  const handleFiltro = (tipo) => {
    switch (tipo) {
      case "novo":
        setFiltro({
          ...filtro,
          novo: !filtro.novo,
        });
        filtrarManutencoes({ ...filtro, novo: !filtro.novo });
        break;
      case "aguardandoConclusao":
        setFiltro({
          ...filtro,
          aguardandoConclusao: !filtro.aguardandoConclusao,
        });
        filtrarManutencoes({
          ...filtro,
          aguardandoConclusao: !filtro.aguardandoConclusao,
        });
        break;
      case "finalizado":
        setFiltro({
          ...filtro,
          finalizado: !filtro.finalizado,
        });
        filtrarManutencoes({ ...filtro, finalizado: !filtro.finalizado });
        break;
      default:
        break;
    }
  };

  const handleCloseExclusaoManutencao = useCallback(() => {
    setDadosExclusaoManutencao({
      open: false,
    });
  }, []);

  const handleOpenExclusaoManutencao = useCallback((values) => {
    setDadosExclusaoManutencao({
      open: true,
      manutencao: values,
    });
  }, []);

  const handleCloseSituacaoManutencao = useCallback(() => {
    setDadosSituacaoManutencao({
      open: false,
    });
  }, []);

  const handleOpenSituacaoManutencao = useCallback((values) => {
    setDadosSituacaoManutencao({
      open: true,
      manutencao: values,
    });
  }, []);

  const handleCloseSelecionarCliente = useCallback(() => {
    setDadosSelecionarCliente({
      open: false,
    });
  }, []);

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <StyledContainer container spacing={2}>
        <Grid item xs={12}>
          <StyledH1>Lista de Solicitações</StyledH1>
          <StyledH4>{!loading ? usuarioCliente.nome : ""}</StyledH4>
        </Grid>
        {!loading && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={8}
                style={{ alignItems: "center", display: "flex" }}
              >
                <FilterChip
                  active={filtro.novo}
                  label={"NOVO"}
                  onClick={() => handleFiltro("novo")}
                />
                <FilterChip
                  active={filtro.aguardandoConclusao}
                  label={"AGUARDANDO CONCLUSÃO"}
                  onClick={() => handleFiltro("aguardandoConclusao")}
                />
                <FilterChip
                  active={filtro.finalizado}
                  label={"FINALIZADO"}
                  onClick={() => handleFiltro("finalizado")}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!!loading && (
          <StyledItem item xs={12}>
            <Spinner />
          </StyledItem>
        )}
        {!loading && (
          <Grid item xs={12}>
            <StyledContainer
              container
              spacing={2}
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              {data?.length > 0 && (
                <>
                  {data.map((i) => (
                    <Grid item key={i.id} xs={12} md={4}>
                      <CardAtualizacao
                        manutencao={i}
                        handleOpenExclusaoBeneficiario={
                          handleOpenExclusaoManutencao
                        }
                        handleDownloadArtefato={handleDownloadArtefato}
                        usuarioCliente={usuarioCliente}
                        handleOpenSituacaoManutencao={
                          handleOpenSituacaoManutencao
                        }
                      />
                    </Grid>
                  ))}
                </>
              )}
              {data?.length === 0 && (
                <Grid item xs={12}>
                  Não existem atualizações para esse cliente
                </Grid>
              )}
              <Grid item xs={12}>
                <StyledContainer
                  container
                  spacing={2}
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <Grid item xs={8}>
                    <Pagination
                      count={Math.ceil(count / 9)}
                      page={page}
                      onChange={(e, page) => handlePagination(page, filtro)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <OutlinedButton onClick={abrirNovo}>
                      + Incluir Atualização
                    </OutlinedButton>
                  </Grid>
                </StyledContainer>
              </Grid>
            </StyledContainer>
          </Grid>
        )}
        <ConfirmarExclusaoDialog
          open={dadosExclusaoManutencao.open}
          handleClose={handleCloseExclusaoManutencao}
          excluir={handleDeleteManutencao}
          manutencao={dadosExclusaoManutencao.manutencao}
        />
        <AlterarSituacaoDialog
          open={dadosSituacaoManutencao.open}
          handleClose={handleCloseSituacaoManutencao}
          alterar={handleAlterarSituacao}
          manutencao={dadosSituacaoManutencao.manutencao}
        />
        <SelecionarClienteDialog
          open={dadosSelecionarCliente.open}
          handleClose={handleCloseSelecionarCliente}
          handleConfirmarCliente={handleConfirmarCliente}
        />
      </StyledContainer>
    </StyledBasePage>
  );
};

export default ManutencaoClientePage;
