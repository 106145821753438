import React from "react";
import { Card, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledTextField,
  CpfCnpjTextField,
  ControlledSelect,
} from "../../../components/inputs";
import { genero, relacaoParentesco } from "../../../utils/parametros";
import { MenuItem } from "../../../components";
import { getManutencoes } from "../../../selectors/dominios.selectors";
import { useSelector } from "react-redux";
import formatters from "../../../utils/formatters";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 15px;
  background-color: #415781ff;
  margin-top: -5px;
  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledControlledCustomTextField = styled(ControlledTextField)`
  .MuiOutlinedInput-input {
    color-scheme: dark;
  }
`;

const CardBeneficiarioIdentificacao = ({
  formProps,
  isDependente,
  editavel,
  preencherValorPremio,
}) => {
  const manutencoes = useSelector(getManutencoes);

  return (
    <StyledCard>
      <StyledH2>Identificação</StyledH2>
      <StyledGridContainer container>
        <StyledGrid item xs={12} md={3}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="cpf"
            label="CPF"
            type={"cpf"}
            disabled={!editavel}
            InputProps={{
              inputComponent: CpfCnpjTextField,
            }}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={9}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="nomeBeneficiario"
            label="Nome Beneficiário"
            inputProps={{ maxLength: 100 }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={3}>
          <StyledControlledCustomTextField
            formProps={formProps}
            fullWidth
            name="dataNascimento"
            label="Data Nascimento"
            type="date"
            disabled={!editavel}
            onChange={(e) => {
              preencherValorPremio(e.target.value);
            }}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={3}>
          <ControlledSelect
            name={"genero"}
            formProps={formProps}
            label={"Gênero"}
            margin={"none"}
            disabled={!editavel}
          >
            {genero.map((item) => (
              <MenuItem
                className={"select-submenu-item"}
                key={item?.id}
                value={item?.id}
              >
                {item.nome}
              </MenuItem>
            ))}
          </ControlledSelect>
        </StyledGrid>
        {!isDependente && (
          <StyledGrid item xs={12} md={6}>
            <ControlledTextField
              formProps={formProps}
              fullWidth
              name="matricula"
              label="Matrícula"
              disabled={!editavel}
              inputProps={{
                maxLength: 30,
              }}
            />
          </StyledGrid>
        )}
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="nomeMae"
            label="Nome da Mãe"
            disabled={!editavel}
            inputProps={{
              maxLength: 100,
            }}
          />
        </StyledGrid>
        {!!isDependente && (
          <StyledGrid item xs={12} md={6}>
            <ControlledSelect
              name={"relacaoDependencia"}
              formProps={formProps}
              label={"Relação de Dependência"}
              margin={"none"}
              disabled={!editavel}
            >
              {relacaoParentesco.map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  key={item?.id}
                  value={item?.id}
                >
                  {item.nome}
                </MenuItem>
              ))}
            </ControlledSelect>
          </StyledGrid>
        )}
        {manutencoes.data.length > 0 && (
          <StyledGrid item xs={12} md={6}>
            <ControlledSelect
              name={"manutencao"}
              formProps={formProps}
              label={"Foi solicitado via manutenção?"}
              margin={"none"}
              disabled={!editavel}
            >
              <MenuItem
                className={"select-submenu-item"}
                key={0}
                value={null}
              ></MenuItem>
              {manutencoes.data.map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  key={item?.id}
                  value={item?.id}
                >
                  {(item.nome ? item.nome + " - "  : "") + formatters.dates.withHours(new Date(item.dataSolicitacao)) + "- Situação: " + (item.situacao === "N" ? "Nova" : "Aguardando Conclusão") }
                </MenuItem>
              ))}
            </ControlledSelect>
          </StyledGrid>
        )}
      </StyledGridContainer>
    </StyledCard>
  );
};

export default CardBeneficiarioIdentificacao;
