import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { actions as manutencaoVidaActions } from "../reducers/manutencaoCliente.actions";
import { actions as dominiosActions } from "../reducers/dominios.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getCliente } from "./../selectors/usuario.selectors";
import { getPayload } from "../selectors/routes.selectors";

const listarManutencoesApi = (values) => {
  return authenticatedRequest({
    url: `/listar-manutencoes`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarManutencoes = basicFlow({
  actionGenerator: manutencaoVidaActions.obterManutencoesCliente,
  api: listarManutencoesApi,
  transform: function* (values) {
    const cliente = yield select(getCliente);
    return {
      pagina: values.pagina ?? 1,
      filtros: values.filtro,
      pesquisa: values.pesquisa ?? "",
      cliente: Number.parseInt(cliente.id),
    };
  },
});

function* listarAtualizacoesRouteWatcher() {
  yield routeWatcher(routes.LISTAR_ATUALIZACOES, function* () {
    const usuarioCliente = yield select(getCliente);
    const payload = yield select(getPayload);
    yield put(dominiosActions.listarClientes.request());
    if (payload.novo) {
      yield put(
        manutencaoVidaActions.obterManutencoesCliente.request({
          filtro: {
            novo: true,
            aguardandoConclusao: false,
            finalizado: false,
          },
        })
      );
    } else if (payload.aguardando) {
      yield put(
        manutencaoVidaActions.obterManutencoesCliente.request({
          filtro: {
            novo: false,
            aguardandoConclusao: true,
            finalizado: false,
          },
        })
      );
    } else {
      yield put(
        manutencaoVidaActions.obterManutencoesCliente.request({
          filtro: {
            novo: true,
            aguardandoConclusao: true,
            finalizado: true,
          },
          cliente: usuarioCliente.id,
        })
      );
    }
  });
}

const criarManutencaoApi = (values) => {
  return authenticatedRequest({
    url: `/manutencao/new`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const criarManutencao = basicFlow({
  actionGenerator: manutencaoVidaActions.criarManutencaoCliente,
  api: criarManutencaoApi,
  transform: function (payload) {
    const form = new FormData();

    const { cliente, file, nome } = payload;

    form.append("cliente", cliente);
    form.append("nome", nome);
    if (file[0]) {
      form.append("arquivos", file[0], "CC" + file[0].name);
    }

    return form;
  },
  postSuccess: function* () {
    yield toast.success(
      "Solicitação de Atualização de Vida incluída com sucesso",
      {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#203d8b" },
      }
    );

    yield put(routeActions.redirectTo(routes.LISTAR_ATUALIZACOES));
  },
});

const deletarAtualizacaoApi = (values) => {
  return authenticatedRequest({
    url: `/manutencao/delete`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const deletarAtualizacao = basicFlow({
  actionGenerator: manutencaoVidaActions.deletarManutencaoCliente,
  api: deletarAtualizacaoApi,
  postSuccess: function* () {
    yield toast.success("Solicitação excluída com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(
      manutencaoVidaActions.obterManutencoesCliente.request({
        filtro: {
          novo: true,
          aguardandoConclusao: true,
          finalizado: true,
        },
      })
    );
  },
});

const alterarAtualizacaoApi = (values) => {
  return authenticatedRequest({
    url: `/manutencao/alterar`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const alterarAtualizacao = basicFlow({
  actionGenerator: manutencaoVidaActions.alterarSituacao,
  api: alterarAtualizacaoApi,
  postSuccess: function* () {
    yield toast.success("Situação atualizada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(
      manutencaoVidaActions.obterManutencoesCliente.request({
        filtro: {
          novo: true,
          aguardandoConclusao: true,
          finalizado: true,
        },
      })
    );
  },
});

export const sagas = [
  listarAtualizacoesRouteWatcher(),
  listarManutencoes.watcher(),
  deletarAtualizacao.watcher(),
  criarManutencao.watcher(),
  alterarAtualizacao.watcher(),
];
