import FormDocumentosManutencaoCliente from "./FormDocumentosManutencaoCliente";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getManutencao } from "../../selectors/manutencaoCliente.selectors";
import { actions } from "../../reducers/manutencaoCliente.actions";
import { actions as routeActions, types as routes } from "../../reducers/rotas.actions";
import { useForm } from "react-hook-form";

const FormClienteConnected = () => {
  const { loading, cliente } = useSelector(getManutencao);
  const [isFile, setIsFile] = useState(false);
  const [file, setFile] = useState([]);

  const dispatch = useDispatch();

  const rules = {

  }

  const initialValues = {
    nome: "",
  }

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
    initialValues,
  };

  const handleSubmit = (values) => {
    const request = {
      file,
      cliente: cliente,
      ...values
    };

    dispatch(actions.criarManutencaoCliente.request(request));
  };

  const handleVoltar = () => {
    dispatch(routeActions.redirectTo(routes.LISTAR_ATUALIZACOES));
  };

  return (
    <FormDocumentosManutencaoCliente
      loading={loading}
      isFile={isFile}
      setIsFile={setIsFile}
      file={file}
      setFile={setFile}
      handleSubmit={handleSubmit}
      handleVoltar={handleVoltar}
      formProps={formProps}
    />
  );
};

export default FormClienteConnected;
