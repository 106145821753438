import ManutencaoClientePage from "./ManutencaoClientePage";
import { getPayload } from "../../selectors/routes.selectors";
import { getCliente } from "../../selectors/usuario.selectors";
import { getManutencoes } from "../../selectors/manutencaoCliente.selectors";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions } from "../../reducers/manutencaoCliente.actions";
import { actions as documentosActions } from "../../reducers/documentos.actions";
import { useCallback } from "react";

const ManutencaoClienteConnected = () => {
  const dispatch = useDispatch();
  const { loading, data, page, count, filter } = useSelector(getManutencoes);
  const payload = useSelector(getPayload);
  const usuarioCliente = useSelector(getCliente);

  const handleNewAtualizacao = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.NEW_ATUALIZACAO, {
        idCliente: usuarioCliente.id,
      })
    );
    //eslint-disable-next-line
  }, [dispatch, payload]);

  const handleConfirmarCliente = useCallback((values) => {
    dispatch(
      routeActions.redirectTo(routes.NEW_ATUALIZACAO, {
        idCliente: values,
      })
    );
  },[dispatch]);

  const handleEditAtualizacao = useCallback(
    (id, idCliente) => {
      dispatch(
        routeActions.redirectTo(routes.MOSTRAR_ATUALIZACAO, {
          idBeneficiario: id,
          idCliente: idCliente,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleDeleteAtualizacao = useCallback(
    (manutencao) => {
      dispatch(
        actions.deletarManutencaoCliente.request({
          manutencao: manutencao.id,
        })
      );
      //eslint-disable-next-line
    },
    //eslint-disable-next-line
    [dispatch, payload]
  );

  const handleDownloadArtefato = useCallback(
    (artefato) => {
      dispatch(
        documentosActions.baixarArtefato.request({
          artefato: artefato,
          filename: "ManutencaoCliente" + Date.now(),
        })
      );
    },
    [dispatch]
  );

  const handleAlterarSituacao = useCallback(
    (id, values) => {
      dispatch(
        actions.alterarSituacao.request({ id: id, situacao: values.situacao })
      );
    },
    [dispatch]
  );

  const filtrarManutencoes = useCallback(
    (filtro) => {
      dispatch(actions.obterManutencoesCliente.request({ filtro: filtro }));
    },
    [dispatch]
  );

  const handlePagination = useCallback(
    (page, filtro) => {
      dispatch(
        actions.obterManutencoesCliente.request({
          filtro: filtro,
          pagina: page,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  return (
    <ManutencaoClientePage
      filtro={filter}
      loading={loading}
      data={data}
      page={page}
      count={count}
      handleNewAtualizacao={handleNewAtualizacao}
      handleEditBeneficiario={handleEditAtualizacao}
      handleDeleteManutencao={handleDeleteAtualizacao}
      usuarioCliente={usuarioCliente}
      handleDownloadArtefato={handleDownloadArtefato}
      handleAlterarSituacao={handleAlterarSituacao}
      filtrarManutencoes={filtrarManutencoes}
      handlePagination={handlePagination}
      handleConfirmarCliente={handleConfirmarCliente}
      cliente={usuarioCliente.id}
    />
  );
};

export default ManutencaoClienteConnected;
