import { Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import Card from "../../components/Card";
import { DeleteOutlined } from "@mui/icons-material";
import formatters from "../../utils/formatters";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const IconsGrid = styled("div")`
  margin-top: 20px;
  align-items: center;
  display: flex;
  width: 100%;
`;

const StyledCardActive = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const CardTitle = styled("div")`
  font-family: nunito;
  font-size: 18px;
  font-weight: 700;
  font-spacing: 0.5px;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CnpjBeneficiario = styled("div")`
  font-family: nunito;
  font-size: 12px;
  font-weight: 400;
  font-spacing: 0.4px;
  color: #ffffff;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledDownloadOutlinedIcon = styled(DownloadOutlinedIcon)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const ProdutoChips = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  font-spacing: 0.5px;
  color: #fff;
  background-color: #f3622c;
  min-width: 70px;
  border-radius: 25px;
  text-align: center;
  margin: auto;
  margin-top: 0px;
  display: block;
  position: absolute;
  left: 20px;
  padding-left: 5px;
  padding-right: 7px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  cursor: pointer;
`;

const CardAtualizacao = ({
  manutencao,
  handleOpenExclusaoBeneficiario,
  handleDownloadArtefato,
  usuarioCliente,
  handleOpenSituacaoManutencao,
}) => {
  return (
    <StyledCardActive key={manutencao.id} style={{ position: "relative" }}>
      <Grid container spacing={1}>
        <StyledGrid item xs={11}>
          <CardTitle>{manutencao?.Cliente?.nomeCliente}</CardTitle>
        </StyledGrid>
      </Grid>
      <StyledGrid item xs={12} md={12}>
        <CnpjBeneficiario>
          {manutencao?.nome}
        </CnpjBeneficiario>
      </StyledGrid>
      <StyledGrid item xs={12} md={12}>
        <CnpjBeneficiario>
          {formatters.dates.short(new Date(manutencao.dataSolicitacao))}
        </CnpjBeneficiario>
      </StyledGrid>
      <IconsGrid>
        <div
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          <Tooltip title={"Download"}>
            <StyledDownloadOutlinedIcon
              onClick={() => {
                handleDownloadArtefato(manutencao.documentos.artefato);
              }}
            />
          </Tooltip>
          {!!usuarioCliente.id && manutencao.situacao === "N" && (
            <Tooltip title={"Excluir"}>
              <StyledDeleteOutlined
                onClick={() => handleOpenExclusaoBeneficiario(manutencao)}
              />
            </Tooltip>
          )}
        </div>
      </IconsGrid>
      <ProdutoChips
        style={{ top: 94, pointer: "clickable" }}
        onClick={
          !usuarioCliente.id
            ? () => handleOpenSituacaoManutencao(manutencao)
            : ""
        }
      >
        {manutencao.situacao === "N"
          ? "Novo"
          : manutencao.situacao === "F"
          ? "Finalizado"
          : "Aguardando Conclusão"}
      </ProdutoChips>
    </StyledCardActive>
  );
};

export default CardAtualizacao;
