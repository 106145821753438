import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import FormEditarRateioPage from "./FormEditarRateioPage";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { getApolice } from "../../../selectors/apolice.selectors";
import { getPayload } from "../../../selectors/routes.selectors";
import { actions } from "../../../reducers/rateio.actions";
import {
  getRateio,
  getRateioBeneficiarios,
  getRateioOutros,
  getRateioSituacao,
  getRateios,
} from "../../../selectors/rateio.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";

const FormEditarRateioPageConnected = () => {
  const { data: dataRateioOutros } = useSelector(getRateioOutros);
  const {
    data: dataRateioBeneficiarios,
    planos: planosBeneficiarios,
  } = useSelector(getRateioBeneficiarios);
  const cliente = useSelector(getPayload);
  const dispatch = useDispatch();
  const [isFileNF, setIsFileNF] = useState(false);
  const [editFileNF, setEditFileNF] = useState(false);
  const [fileNF, setFileNF] = useState([]);
  const [isFileBO, setIsFileBO] = useState(false);
  const [fileBO, setFileBO] = useState([]);
  const [editFileBO, setEditFileBO] = useState(false);
  const [isFileFA, setIsFileFA] = useState(false);
  const [fileFA, setFileFA] = useState([]);
  const [editFileFA, setEditFileFA] = useState(false);
  const [isFileCP, setIsFileCP] = useState(false);
  const [fileCP, setFileCP] = useState([]);
  const [editFileCP, setEditFileCP] = useState(false);
  const [isFileOD, setIsFileOD] = useState(false);
  const [fileOD, setFileOD] = useState([]);
  const [arrayFileOD, setArrayFileOD] = useState([]);
  const { loading } = useSelector(getApolice);
  const [buttonTitle, setButtonTitle] = useState("+ Incluir");
  const { data: rateio, loading: loadingRateio } = useSelector(getRateio);
  const { data: situacao } = useSelector(getRateioSituacao);
  const listaRateios = useSelector(getRateios);

  const rules = {
    dataCompetencia: validators.string({ required: true }),
    valorOutros: validators.number({ required: true }),
    tipoValor: validators.string({ required: true }),
    descricao: validators.string({ required: true }),
    observacao: validators.string({ required: false }),
  };

  const initialValues = {
    dataCompetencia: `${new Date().getFullYear()}-${
      new Date().getMonth() > 8
        ? new Date().getMonth() + 1
        : "0" + (new Date().getMonth() + 1)
    }`,
    valorOutros: 0,
    tipoValor: "",
    observacao: "",
    title: "+ Incluir",
    id: "",
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  const handleSubmit = (values) => {
    if (buttonTitle === "+ Incluir") {
      values.clienteRateio = Number(cliente.idRateio);
      dispatch(actions.criarRateioOutros.request(values));
      formProps.reset();
    } else {
      dispatch(actions.editarRateioOutros.request(values));
      setButtonTitle("+ Incluir");
      formProps.reset();
    }
    formProps.reset();
  };

  const handleDeleteRateioOutros = (id) => {
    dispatch(actions.deletarRateioOutros.request({ id }));
  };

  const handleSetEdit = (values) => {
    formProps.setValue(
      "dataCompetencia",
      `${new Date(values.dataCompetencia).getFullYear()}-${
        new Date(values.dataCompetencia).getMonth() > 7
          ? new Date(values.dataCompetencia).getMonth() + 2
          : "0" + String(new Date(values.dataCompetencia).getMonth() + 2)
      }`
    );

    formProps.setValue("tipoValor", values.tipoValor);
    formProps.setValue("valorOutros", values.valorOutros.replace(".", ","));
    formProps.setValue("observacao", values.observacao);
    formProps.setValue("id", values.id);
    setButtonTitle("Salvar");
  };

  const handleClickVoltar = useCallback(
    () => {
      if (cliente.isPainel) {
        dispatch(
          routeActions.redirectTo(routes.PAINEL, {
            isRateio: true,
            page: cliente.page,
          })
        );
      } else {
        dispatch(
          routeActions.redirectTo(routes.RATEIOS, {
            idCliente: listaRateios.cliente.idCliente,
          })
        );
      }
    },
    //eslint-disable-next-line
    [dispatch, listaRateios]
  );

  useEffect(() => {
    formProps.reset();

    if (rateio) {
      if (rateio?.documentoNF) {
        setEditFileNF(rateio?.documentoNF);
      } else {
        setEditFileNF();
      }
      if (rateio?.documentoBO) {
        setEditFileBO(rateio?.documentoBO);
      } else {
        setEditFileBO();
      }
      if (rateio?.documentoFA) {
        setEditFileFA(rateio?.documentoFA);
      } else {
        setEditFileFA();
      }
      if (rateio?.documentoCP) {
        setEditFileCP(rateio?.documentoCP);
      } else {
        setEditFileCP();
      }
      if (rateio?.documentoOD) {
        let files = [];
        for (let fileODInside of rateio.documentoOD) {
          files.push(fileODInside);
        }
        setArrayFileOD(files);
      } else {
        setArrayFileOD([]);
      }
    }
    //eslint-disable-next-line
  }, [rateio]);

  const handleClickUploadDocumentos = () => {
    dispatch(
      actions.uploadRateioDocumentos.request({
        id: cliente.idRateio,
        isFileBO,
        fileBO,
        editFileBO,
        isFileNF,
        fileNF,
        editFileNF,
        isFileFA,
        fileFA,
        editFileFA,
        isFileCP,
        fileCP,
        editFileCP,
        isFileOD,
        fileOD,
        arrayFileOD,
      })
    );

    setIsFileBO(false);
    setFileBO([]);
    setEditFileBO(false);
    setIsFileNF(false);
    setFileNF([]);
    setEditFileNF(false);
    setIsFileFA(false);
    setFileFA([]);
    setEditFileFA(false);
    setIsFileCP(false);
    setFileCP([]);
    setEditFileCP(false);
    setIsFileOD(false);
    setFileOD([]);
    setArrayFileOD(false);
  };

  const handleDownloadArtefato = (values) => {
    dispatch(
      actions.baixarArtefato.request({
        artefato: values.artefato,
        filename: values.filename,
      })
    );
  };

  const handleIncluirBeneficiario = useCallback(
    () => {
      dispatch(
        routeActions.redirectTo(routes.NEW_BENEFICIARIO, {
          idCliente: rateio.Cliente.id,
          idRateio: cliente.idRateio,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch, rateio]
  );

  const handleAprovar = (values) => {
    dispatch(
      actions.aprovarSituacao.request({
        situacao: "A",
        id: cliente.idRateio,
      })
    );
  };

  return (
    <FormEditarRateioPage
      loading={loading}
      formProps={formProps}
      buttonTitle={buttonTitle}
      handleSubmit={handleSubmit}
      isFileBO={isFileBO}
      setIsFileBO={setIsFileBO}
      fileBO={fileBO}
      setFileBO={setFileBO}
      editFileBO={editFileBO}
      setEditFileBO={setEditFileBO}
      isFileNF={isFileNF}
      setIsFileNF={setIsFileNF}
      setFileNF={setFileNF}
      fileNF={fileNF}
      editFileNF={editFileNF}
      setEditFileNF={setEditFileNF}
      isFileFA={isFileFA}
      setIsFileFA={setIsFileFA}
      setFileFA={setFileFA}
      fileFA={fileFA}
      editFileFA={editFileFA}
      setEditFileFA={setEditFileFA}
      isFileCP={isFileCP}
      setIsFileCP={setIsFileCP}
      setFileCP={setFileCP}
      fileCP={fileCP}
      editFileCP={editFileCP}
      setEditFileCP={setEditFileCP}
      isFileOD={isFileOD}
      setIsFileOD={setIsFileOD}
      setFileOD={setFileOD}
      fileOD={fileOD}
      arrayFileOD={arrayFileOD}
      setArrayFileOD={setArrayFileOD}
      dataRateioOutros={dataRateioOutros}
      handleDeleteRateioOutros={handleDeleteRateioOutros}
      handleSetEdit={handleSetEdit}
      rateio={rateio}
      loadingRateio={loadingRateio}
      handleClickVoltar={handleClickVoltar}
      handleClickUploadDocumentos={handleClickUploadDocumentos}
      handleDownloadArtefato={handleDownloadArtefato}
      isDocumento={cliente.isDocumento}
      dataRateioBeneficiarios={dataRateioBeneficiarios}
      planosBeneficiarios={planosBeneficiarios}
      handleIncluirBeneficiario={handleIncluirBeneficiario}
      situacao={situacao}
      handleAprovar={handleAprovar}
    />
  );
};

export default FormEditarRateioPageConnected;
