import React from "react";
import { Box, Card, IconButton, Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  CloudUpload,
  Delete,
  GetApp,
  InsertDriveFile,
} from "@mui/icons-material";
import formatters from "../../utils/formatters";
import FileInput from "../../components/inputs/FileInput";
import BasePage from "../BasePage";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import Button from "../../components/buttons/PrimaryButton";
import { ControlledTextField } from "../../components/inputs";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  font-weight: 400;
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: -30px;
  @media (max-width: 900px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StyledCard = styled(Card)`
  background-color: white;
  width: 100%;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  margin-bottom: 0px;
`;

const StyledCardForm = styled(Card)`
  min-height: 283px;  
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  & .MuiGrid-container {
    align-items: center;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 1.8px;
  font-size: 20px;
  margin-left: 15px;
  margin-bottom: -10px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledH4 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledBox = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background: repeating-linear-gradient(
    135deg,
    #ffffffff,
    #ffffffff 30px,
    #ebf2f8ff 30px,
    #ebf2f8ff 60px
  );

  border: 2px dashed #b4cfe7ff;
  border-radius: 15px;
`;
const StyledBoxFile = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background-color: #ebf2f8ff;

  border: 2px solid #b4cfe7ff;
  border-radius: 15px;
`;

const StyledFileContainer = styled(Box)`
  padding: 20px;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 500px;
  margin-bottom: -65px;
`;

const filesize = (size) => {
  if (size > 1024 * 1024) {
    // Mb
    return `${formatters.numbers.nFixed(
      Math.round(size / (1024 * 1024), 1),
      1
    )} Mb`;
  }
  if (size > 1024) {
    // Mb
    return `${formatters.numbers.nFixed(size / 1024, 1)} Kb`;
  }
  return `${size} bytes`;
};

const FormDocumentosCliente = ({
  isFile,
  setIsFile,
  file,
  setFile,
  editFile,
  setEditFile,
  handleDownloadArtefato,
  handleVoltar,
  handleSubmit,
  loading,
  formProps,
}) => {
  if (loading) {
    return (
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <BasePage isLoggedIn={true} isLightBlue={true}>
      <form onSubmit={formProps.handleSubmit(handleSubmit)}>
        <StyledContainer container spacing={4}>
          <Grid item xs={12}>
            <StyledH1>Nova Atualização de Vida</StyledH1>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "20px" }}>
            <StyledCardForm>
              <ControlledTextField
                formProps={formProps}
                fullWidth
                name="nome"
                label="Nome da Movimentação"
                inputProps={{ maxLength: 50 }}
              />
            </StyledCardForm>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "20px" }}>
            <StyledCard>
              <StyledH2>Documento Atualização Vidas</StyledH2>
              <StyledFileContainer>
                {editFile && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <>
                      <StyledH4>
                        <InsertDriveFile
                          style={{
                            fontSize: "60px",
                            marginTop: "15px",
                            color: "#415781ff",
                          }}
                        />
                      </StyledH4>
                      <StyledH4
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          fontSize: `${
                            editFile.name.length > 25 ? "10px" : "12px"
                          }`,
                        }}
                      >
                        {editFile.name}
                      </StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setEditFile(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{
                            color: "#f00e0e",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={(e) => {
                          handleDownloadArtefato({
                            artefato: editFile.artefato,
                            filename: editFile.name,
                          });
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <GetApp
                          style={{
                            color: "#33c481",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </>
                  </StyledBoxFile>
                )}
                {!editFile && (
                  <FileInput
                    name={"fileSeguradoras"}
                    maxFiles={1}
                    accept={".pdf"}
                    multiple={false}
                    onDrop={(acceptedFiles) => {
                      setFile(acceptedFiles);
                      setIsFile(true);
                    }}
                  >
                    {isFile && (
                      <StyledBoxFile
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <>
                          <StyledH4>
                            <InsertDriveFile
                              style={{
                                fontSize: "60px",
                                marginTop: "15px",
                                color: "#415781ff",
                              }}
                            />
                          </StyledH4>
                          <StyledH4
                            style={{
                              marginLeft: "15px",
                              marginRight: "15px",
                              fontSize: `${
                                file[0].name.length > 25 ? "10px" : "12px"
                              }`,
                            }}
                          >
                            {file[0].name}
                            <br/>
                            {filesize(file[0].size)}
                          </StyledH4>
                          <IconButton
                            onClick={(e) => {
                              setFile([]);
                              setIsFile(false);
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <Delete
                              style={{
                                color: "#f00e0e",
                                cursor: "pointer",
                                fontSize: "30px",
                              }}
                            />
                          </IconButton>
                        </>
                      </StyledBoxFile>
                    )}
                    {!isFile && (
                      <StyledBox
                        sx={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                          "&:hover": {
                            opacity: [0.9, 0.8, 0.7],
                          },
                        }}
                      >
                        <StyledH4 style={{ marginTop: "50px" }}>
                          Arraste o arquivo para cá
                        </StyledH4>
                        <StyledH4>ou clique para selecioná-lo</StyledH4>
                        <StyledH4>
                          <CloudUpload
                            style={{ fontSize: "30px", marginTop: "-5px" }}
                          />
                        </StyledH4>
                      </StyledBox>
                    )}
                  </FileInput>
                )}
              </StyledFileContainer>
            </StyledCard>
          </Grid>
        </StyledContainer>
        <StyledContainer
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "Center",
            marginTop: "10px",
          }}
        >
          <StyledGrid item xs={4}>
            <OutlinedButton onClick={handleVoltar}>Voltar</OutlinedButton>
          </StyledGrid>
          <StyledGrid item xs={4}>
            <Button type={"submit"}>Confirmar</Button>
          </StyledGrid>
        </StyledContainer>
      </form>
    </BasePage>
);
};

export default FormDocumentosCliente;
